import $ from 'jquery';
// import axios from 'axios';
// import Vue from 'vue';
//import 'jquery-mask-plugin';
import 'slick-carousel';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes';
// import 'jquery-ui/ui/core';
// import 'jquery-ui/ui/widget';
// import 'jquery-ui/ui/position';
// import 'jquery-ui/ui/widgets/menu';
import 'jquery-ui/ui/widgets/autocomplete';
// import 'jquery-ui/ui/widgets/button';
// import 'jquery-ui/ui/widgets/dialog';
import '@fancyapps/fancybox';
